// src/pages/HomePage.js
import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import {
  Container,
  Typography,
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Avatar,
  Snackbar,
  Alert,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  Fastfood as FastfoodIcon,
  LocalTaxi as LocalTaxiIcon,
  DeliveryDining as DeliveryDiningIcon,
  Star as StarIcon,
  People as PeopleIcon,
  SupportAgent as SupportAgentIcon,
  Security as SecurityIcon,
  LocalShipping as LocalShippingIcon,
} from '@mui/icons-material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SiGoogleplay, SiAppstore } from 'react-icons/si';

// Import Swiper modules
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// Sample testimonial data
const testimonials = [
  {
    id: 1,
    name: 'John Doe',
    feedback:
      'KIRUPAM provides the best food delivery service. Fast and reliable!',
    avatar: '/images/customer1.jpg',
  },
  {
    id: 2,
    name: 'Jane Smith',
    feedback: 'Booking a taxi with K LIMO is seamless and hassle-free.',
    avatar: '/images/customer2.jpg',
  },
  {
    id: 3,
    name: 'Michael Brown',
    feedback:
      'Excellent customer service and timely deliveries. Highly recommend!',
    avatar: '/images/customer3.jpg',
  },
];

function HomePage() {
  // State for handling testimonial snackbar (if needed)
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <NavBar />

{/* Hero Section with Slider */}
<Box
  component={motion.div}
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ duration: 1 }}
  sx={{
    height: '100vh', // Full viewport height
    position: 'relative',
    overflow: 'hidden',
    bgcolor: '#fbecfb', // Solid white background
  }}
>
  <Swiper
    modules={[Pagination, Navigation, Autoplay]}
    spaceBetween={0}
    slidesPerView={1}
    pagination={{ clickable: true }}
    navigation
    autoplay={{ delay: 5000, disableOnInteraction: false }}
    loop
    style={{ height: '100%' }} // Ensure Swiper takes full height
  >
    {/* Slide 1 */}
    <SwiperSlide>
      <Grid
        container
        spacing={2}
        sx={{
          height: '100%',
          px: { xs: 2, md: 10 }, // Responsive padding
        }}
        alignItems="center"
        justifyContent="center"
      >
        {/* Left Column: Text Content */}
        <Grid item xs={12} md={6}>
          <Box
            component={motion.div}
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            sx={{
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Typography
              variant="h3"
              component="h1"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                color: '#333333',
                fontSize: { xs: '2rem', md: '3rem' },
              }}
            >
              Order Your Favorite Meals
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mb: 4,
                color: '#555555',
                fontSize: { xs: '1rem', md: '1.25rem' },
              }}
            >
              Enjoy a wide variety of cuisines delivered straight to your
              doorstep with just a few taps.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/food-order"
              size="large"
              sx={{
                px: 4,
                py: 1.5,
                fontSize: '1rem',
                textTransform: 'none',
                borderRadius: 2,
              }}
            >
              Order Food
            </Button>
          </Box>
        </Grid>

        {/* Right Column: App Screenshots */}
        <Grid item xs={12} md={6}>
          <Box
            component={motion.div}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', md: 'flex-end' },
            }}
          >
            {/* Main Screenshot */}
            <Box
              component="img"
              src="/images/food.png" // Replace with your app screenshot
              alt="App Screenshot"
              sx={{
                width: { xs: '60%', sm: '50%', md: '70%' },
                maxWidth: 300,
                borderRadius: 2,
                boxShadow: 3,
                zIndex: 2,
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            />

            {/* Secondary Screenshot */}
            <Box
              component="img"
              src="/images/food.png" // Replace with your app screenshot
              alt="App Screenshot"
              sx={{
                position: 'absolute',
                top: 20,
                left: 20,
                width: { xs: '40%', sm: '30%', md: '40%' },
                maxWidth: 200,
                borderRadius: 2,
                boxShadow: 3,
                zIndex: 1,
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </SwiperSlide>

    {/* Slide 2 */}
    <SwiperSlide>
      <Grid
        container
        spacing={2}
        sx={{
          height: '100%',
          px: { xs: 2, md: 10 },
        }}
        alignItems="center"
        justifyContent="center"
      >
        {/* Left Column: Text Content */}
        <Grid item xs={12} md={6}>
          <Box
            component={motion.div}
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            sx={{
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Typography
              variant="h3"
              component="h1"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                color: '#333333',
                fontSize: { xs: '2rem', md: '3rem' },
              }}
            >
              Book a Reliable Taxi
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mb: 4,
                color: '#555555',
                fontSize: { xs: '1rem', md: '1.25rem' },
              }}
            >
              Experience seamless and hassle-free taxi bookings with our
              trusted drivers.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/taxi-booking"
              size="large"
              sx={{
                px: 4,
                py: 1.5,
                fontSize: '1rem',
                textTransform: 'none',
                borderRadius: 2,
              }}
            >
              Book a Taxi
            </Button>
          </Box>
        </Grid>

        {/* Right Column: App Screenshots */}
        <Grid item xs={12} md={6}>
          <Box
            component={motion.div}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', md: 'flex-end' },
            }}
          >
            {/* Main Screenshot */}
            <Box
              component="img"
              src="/images/taxi.png" // Replace with your app screenshot
              alt="App Screenshot"
              sx={{
                width: { xs: '60%', sm: '50%', md: '70%' },
                maxWidth: 300,
                borderRadius: 2,
                boxShadow: 3,
                zIndex: 2,
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            />

            {/* Secondary Screenshot */}
            <Box
              component="img"
              src="/images/taxi.png" // Replace with your app screenshot
              alt="App Screenshot"
              sx={{
                position: 'absolute',
                top: 20,
                left: 20,
                width: { xs: '40%', sm: '30%', md: '40%' },
                maxWidth: 200,
                borderRadius: 2,
                boxShadow: 3,
                zIndex: 1,
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </SwiperSlide>

    {/* Slide 3 */}
    <SwiperSlide>
      <Grid
        container
        spacing={2}
        sx={{
          height: '100%',
          px: { xs: 2, md: 10 },
        }}
        alignItems="center"
        justifyContent="center"
      >
        {/* Left Column: Text Content */}
        <Grid item xs={12} md={6}>
          <Box
            component={motion.div}
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            sx={{
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Typography
              variant="h3"
              component="h1"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                color: '#333333',
                fontSize: { xs: '2rem', md: '3rem' },
              }}
            >
              Fast and Secure Delivery
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mb: 4,
                color: '#555555',
                fontSize: { xs: '1rem', md: '1.25rem' },
              }}
            >
              Your orders are handled with utmost care, ensuring they reach
              you quickly and safely.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/delivery-service"
              size="large"
              sx={{
                px: 4,
                py: 1.5,
                fontSize: '1rem',
                textTransform: 'none',
                borderRadius: 2,
              }}
            >
              Learn More
            </Button>
          </Box>
        </Grid>

        {/* Right Column: App Screenshots */}
        <Grid item xs={12} md={6}>
          <Box
            component={motion.div}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', md: 'flex-end' },
            }}
          >
            {/* Main Screenshot */}
            <Box
              component="img"
              src="/images/rider.png" // Replace with your app screenshot
              alt="App Screenshot"
              sx={{
                width: { xs: '60%', sm: '50%', md: '70%' },
                maxWidth: 300,
                borderRadius: 2,
                boxShadow: 3,
                zIndex: 2,
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            />

            {/* Secondary Screenshot */}
            <Box
              component="img"
              src="/images/rider.png" // Replace with your app screenshot
              alt="App Screenshot"
              sx={{
                position: 'absolute',
                top: 20,
                left: 20,
                width: { xs: '40%', sm: '30%', md: '40%' },
                maxWidth: 200,
                borderRadius: 2,
                boxShadow: 3,
                zIndex: 1,
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </SwiperSlide>
  </Swiper>
</Box>



      {/* About Us Section */}
      <Container sx={{ mt: 8, mb: 8 }}>
        <Grid container spacing={6} alignItems="center">
          {/* Text Content */}
          <Grid item xs={12} md={6}>
            <Box
              component={motion.div}
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Typography variant="h4" gutterBottom color="secondary.main">
                About KIRUPAM
              </Typography>
              <Typography variant="body1" paragraph>
                At KIRUPAM (PVT) LTD, we strive to provide exceptional services
                in food ordering, delivery, and taxi bookings. Our mission is
                to make your life easier by offering reliable and efficient
                solutions tailored to your needs.
              </Typography>
              <Typography variant="body1" paragraph>
                Whether you're craving your favorite meal delivered to your
                doorstep or need a swift and safe ride across town, KIRUPAM has
                you covered. Join thousands of satisfied customers who trust us
                for their daily necessities.
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                component={Link}
                to="/about-us"
                size="large"
                sx={{ mt: 2 }}
              >
                Learn More
              </Button>
            </Box>
          </Grid>

          {/* Image */}
          <Grid item xs={12} md={6}>
            <Box
              component={motion.div}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Box
                component="img"
                src="/images/abki.png"
                alt="About KIRUPAM"
                sx={{
                  width: '100%',
                  borderRadius: 3,
                  boxShadow: 3,
                  transition: 'transform 0.3s',
                  '&:hover': { transform: 'scale(1.05)' },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Services Section */}
      <Box sx={{ bgcolor: '#F3F4F6', py: 8 }}>
      <Container>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          color="secondary.main"
          sx={{ fontWeight: 'bold' }}
        >
          Our Services
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          paragraph
        >
          Discover the range of services we offer to cater to all your needs.
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {/* Service 1: Food Order App */}
          <Grid item xs={12} sm={6} md={3}>
            <Card
              component={motion.div}
              whileHover={{
                scale: 1.05,
                boxShadow: '0px 10px 20px rgba(0,0,0,0.2)',
              }}
              sx={{
                height: '100%',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s, box-shadow 0.3s',
                borderRadius: 2,
                overflow: 'hidden',
                bgcolor: '#FFFFFF',
              }}
              elevation={3}
            >
              <CardMedia
                component="img"
                height="160"
                image="/images/foodorder.png" // Replace with your image path
                alt="KIRUPAM Food Order App"
                sx={{
                  objectFit: 'contain', // Ensures the image fits within the container
                  bgcolor: '#F9FAFB', // Light background to highlight the image
                }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <FastfoodIcon color="secondary" sx={{ mr: 1 }} />
                  <Typography variant="h6" color="secondary.main">
                    Food Order App
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  Browse a wide variety of restaurants and cuisines. Order your
                  favorite meals with just a few taps.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  component={Link}
                  to="/food-order"
                  color="secondary"
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  Order Now
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/* Service 2: Delivery Service */}
          <Grid item xs={12} sm={6} md={3}>
            <Card
              component={motion.div}
              whileHover={{
                scale: 1.05,
                boxShadow: '0px 10px 20px rgba(0,0,0,0.2)',
              }}
              sx={{
                height: '100%',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s, box-shadow 0.3s',
                borderRadius: 2,
                overflow: 'hidden',
                bgcolor: '#FFFFFF',
              }}
              elevation={3}
            >
              <CardMedia
                component="img"
                height="160"
                image="/images/riderservice.png" // Replace with your image path
                alt="KIRUPAM Delivery Service"
                sx={{
                  objectFit: 'contain',
                  bgcolor: '#F9FAFB',
                }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <DeliveryDiningIcon color="secondary" sx={{ mr: 1 }} />
                  <Typography variant="h6" color="secondary.main">
                    Delivery Service
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  Reliable and efficient delivery partners ensure your orders reach
                  you on time, every time.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  component={Link}
                  to="/delivery-service"
                  color="secondary"
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/* Service 3: Taxi Booking */}
          <Grid item xs={12} sm={6} md={3}>
            <Card
              component={motion.div}
              whileHover={{
                scale: 1.05,
                boxShadow: '0px 10px 20px rgba(0,0,0,0.2)',
              }}
              sx={{
                height: '100%',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s, box-shadow 0.3s',
                borderRadius: 2,
                overflow: 'hidden',
                bgcolor: '#FFFFFF',
              }}
              elevation={3}
            >
              <CardMedia
                component="img"
                height="160"
                image="/images/texibooking.png" // Replace with your image path
                alt="KIRUPAM Taxi Booking"
                sx={{
                  objectFit: 'contain',
                  bgcolor: '#F9FAFB',
                }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <LocalTaxiIcon color="secondary" sx={{ mr: 1 }} />
                  <Typography variant="h6" color="secondary.main">
                    Taxi Booking
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  Book reliable and comfortable taxis for your daily commutes or
                  special occasions with ease.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  component={Link}
                  to="/taxi-booking"
                  color="secondary"
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  Book Now
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/* Service 4: Driver App */}
          <Grid item xs={12} sm={6} md={3}>
            <Card
              component={motion.div}
              whileHover={{
                scale: 1.05,
                boxShadow: '0px 10px 20px rgba(0,0,0,0.2)',
              }}
              sx={{
                height: '100%',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s, box-shadow 0.3s',
                borderRadius: 2,
                overflow: 'hidden',
                bgcolor: '#FFFFFF',
              }}
              elevation={3}
            >
              <CardMedia
                component="img"
                height="160"
                image="/images/taxidriver.png" // Replace with your image path
                alt="KIRUPAM Driver App"
                sx={{
                  objectFit: 'contain',
                  bgcolor: '#F9FAFB',
                }}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <PeopleIcon color="secondary" sx={{ mr: 1 }} />
                  <Typography variant="h6" color="secondary.main">
                    Driver App
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  Join our team of professional drivers and earn on your own
                  schedule with the KIRUPAM Driver App.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  component={Link}
                  to="/driver-app"
                  color="secondary"
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  Join Us
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>

      {/* Features Section */}
      <Container sx={{ mt: 8, mb: 8 }}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          color="secondary.main"
        >
          Why Choose Us
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          paragraph
        >
          We are committed to providing top-notch services that stand out from the rest.
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {/* Feature 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              component={motion.div}
              whileHover={{ scale: 1.05 }}
              sx={{
                textAlign: 'center',
                p: 4,
                borderRadius: 2,
                boxShadow: 3,
                bgcolor: '#FFFFFF',
                transition: 'transform 0.3s',
              }}
            >
              <Avatar
                sx={{
                  bgcolor: 'secondary.main',
                  width: 64,
                  height: 64,
                  margin: '0 auto',
                  mb: 2,
                  fontSize: 32,
                }}
              >
                🍔
              </Avatar>
              <Typography variant="h6" gutterBottom color="secondary.main">
                Wide Variety
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Choose from a vast selection of restaurants and cuisines to
                satisfy every palate.
              </Typography>
            </Box>
          </Grid>

          {/* Feature 2 */}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              component={motion.div}
              whileHover={{ scale: 1.05 }}
              sx={{
                textAlign: 'center',
                p: 4,
                borderRadius: 2,
                boxShadow: 3,
                bgcolor: '#FFFFFF',
                transition: 'transform 0.3s',
              }}
            >
              <Avatar
                sx={{
                  bgcolor: 'secondary.main',
                  width: 64,
                  height: 64,
                  margin: '0 auto',
                  mb: 2,
                  fontSize: 32,
                }}
              >
                🚗
              </Avatar>
              <Typography variant="h6" gutterBottom color="secondary.main">
                Reliable Delivery
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Our dedicated delivery partners ensure your orders arrive
                promptly and in perfect condition.
              </Typography>
            </Box>
          </Grid>

          {/* Feature 3 */}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              component={motion.div}
              whileHover={{ scale: 1.05 }}
              sx={{
                textAlign: 'center',
                p: 4,
                borderRadius: 2,
                boxShadow: 3,
                bgcolor: '#FFFFFF',
                transition: 'transform 0.3s',
              }}
            >
              <Avatar
                sx={{
                  bgcolor: 'success.main',
                  width: 64,
                  height: 64,
                  margin: '0 auto',
                  mb: 2,
                  fontSize: 32,
                }}
              >
                🕒
              </Avatar>
              <Typography variant="h6" gutterBottom color="secondary.main">
                24/7 Support
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Our customer support team is available around the clock to assist
                you with any inquiries.
              </Typography>
            </Box>
          </Grid>

          {/* Feature 4 */}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              component={motion.div}
              whileHover={{ scale: 1.05 }}
              sx={{
                textAlign: 'center',
                p: 4,
                borderRadius: 2,
                boxShadow: 3,
                bgcolor: '#FFFFFF',
                transition: 'transform 0.3s',
              }}
            >
              <Avatar
                sx={{
                  bgcolor: 'error.main',
                  width: 64,
                  height: 64,
                  margin: '0 auto',
                  mb: 2,
                  fontSize: 32,
                }}
              >
                🔒
              </Avatar>
              <Typography variant="h6" gutterBottom color="secondary.main">
                Secure Payments
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Enjoy safe and secure payment options, ensuring your financial
                information is protected.
              </Typography>
            </Box>
          </Grid>

          {/* Feature 5 */}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              component={motion.div}
              whileHover={{ scale: 1.05 }}
              sx={{
                textAlign: 'center',
                p: 4,
                borderRadius: 2,
                boxShadow: 3,
                bgcolor: '#FFFFFF',
                transition: 'transform 0.3s',
              }}
            >
              <Avatar
                sx={{
                  bgcolor: 'warning.main',
                  width: 64,
                  height: 64,
                  margin: '0 auto',
                  mb: 2,
                  fontSize: 32,
                }}
              >
                🌐
              </Avatar>
              <Typography variant="h6" gutterBottom color="secondary.main">
                User-Friendly App
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Our intuitive apps make ordering food and booking taxis
                effortless and enjoyable.
              </Typography>
            </Box>
          </Grid>

          {/* Feature 6 */}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              component={motion.div}
              whileHover={{ scale: 1.05 }}
              sx={{
                textAlign: 'center',
                p: 4,
                borderRadius: 2,
                boxShadow: 3,
                bgcolor: '#FFFFFF',
                transition: 'transform 0.3s',
              }}
            >
              <Avatar
                sx={{
                  bgcolor: 'info.main',
                  width: 64,
                  height: 64,
                  margin: '0 auto',
                  mb: 2,
                  fontSize: 32,
                }}
              >
                ⭐
              </Avatar>
              <Typography variant="h6" gutterBottom color="secondary.main">
                High Ratings
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Our services are highly rated by customers for quality, speed,
                and reliability.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* How It Works Section */}
      <Box sx={{ bgcolor: '#FFFFFF', py: 8 }}>
        <Container>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            color="secondary.main"
          >
            How It Works
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="text.secondary"
            paragraph
          >
            Simple steps to get started with KIRUPAM.
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {/* Step 1 */}
            <Grid item xs={12} sm={6} md={3}>
              <Box
                component={motion.div}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  borderRadius: 2,
                  boxShadow: 3,
                  bgcolor: '#F3F4F6',
                  transition: 'transform 0.3s',
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: 'secondary.main',
                    width: 56,
                    height: 56,
                    margin: '0 auto',
                    mb: 2,
                    fontSize: 32,
                  }}
                >
                  📱
                </Avatar>
                <Typography variant="h6" gutterBottom color="secondary.main">
                  Download the App
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Get the KIRUPAM app from Google Play or the App Store.
                </Typography>
              </Box>
            </Grid>

            {/* Step 2 */}
            <Grid item xs={12} sm={6} md={3}>
              <Box
                component={motion.div}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  borderRadius: 2,
                  boxShadow: 3,
                  bgcolor: '#F3F4F6',
                  transition: 'transform 0.3s',
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: 'secondary.main',
                    width: 56,
                    height: 56,
                    margin: '0 auto',
                    mb: 2,
                    fontSize: 32,
                  }}
                >
                  📦
                </Avatar>
                <Typography variant="h6" gutterBottom color="secondary.main">
                  Choose Your Service
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Select from food delivery, taxi booking, or delivery services.
                </Typography>
              </Box>
            </Grid>

            {/* Step 3 */}
            <Grid item xs={12} sm={6} md={3}>
              <Box
                component={motion.div}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  borderRadius: 2,
                  boxShadow: 3,
                  bgcolor: '#F3F4F6',
                  transition: 'transform 0.3s',
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: 'success.main',
                    width: 56,
                    height: 56,
                    margin: '0 auto',
                    mb: 2,
                    fontSize: 32,
                  }}
                >
                  💰
                </Avatar>
                <Typography variant="h6" gutterBottom color="secondary.main">
                  Make a Payment
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Pay securely using your preferred payment method.
                </Typography>
              </Box>
            </Grid>

            {/* Step 4 */}
            <Grid item xs={12} sm={6} md={3}>
              <Box
                component={motion.div}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.2 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  borderRadius: 2,
                  boxShadow: 3,
                  bgcolor: '#F3F4F6',
                  transition: 'transform 0.3s',
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: 'error.main',
                    width: 56,
                    height: 56,
                    margin: '0 auto',
                    mb: 2,
                    fontSize: 32,
                  }}
                >
                  🚚
                </Avatar>
                <Typography variant="h6" gutterBottom color="secondary.main">
                  Enjoy the Service
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Receive your order or ride swiftly and securely.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Partners Section */}


      {/* Testimonials Section */}
      <Box sx={{ bgcolor: '#F3F4F6', py: 8 }}>
        <Container>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            color="secondary.main"
          >
            What Our Customers Say
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="text.secondary"
            paragraph
          >
            Hear from our satisfied customers who trust KIRUPAM for their daily
            needs.
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {testimonials.map((testimonial) => (
              <Grid item xs={12} sm={6} md={4} key={testimonial.id}>
                <Card
                  component={motion.div}
                  whileHover={{
                    scale: 1.02,
                    boxShadow: '0px 8px 30px rgba(0,0,0,0.1)',
                  }}
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                    bgcolor: '#FFFFFF',
                    transition: 'transform 0.3s',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mb: 2,
                    }}
                  >
                    <Avatar
                      alt={testimonial.name}
                      src={testimonial.avatar}
                      sx={{ width: 80, height: 80 }}
                    />
                  </Box>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      color="text.secondary"
                    >
                      "{testimonial.feedback}"
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      align="right"
                      color="secondary.main"
                    >
                      - {testimonial.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

   

      {/* Footer */}
      <Footer />

      {/* Optional Snackbar for general notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%' }}
        >
          Thank you for visiting KIRUPAM!
        </Alert>
      </Snackbar>
    </>
  );
}

export default HomePage;
