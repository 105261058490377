// src/pages/AboutUsPage.js

import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import {
  Container,
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Avatar,
  Paper,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';
import { FaCheckCircle, FaAward, FaRegBuilding, FaMobileAlt, FaGlobe, FaCrown, FaUsers } from 'react-icons/fa';
import { SiGoogleplay, SiAppstore } from 'react-icons/si';
import { motion } from 'framer-motion';

// Import Timeline components from @mui/lab
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@mui/lab';

// Styled Components
const HeroSection = styled(Box)(({ theme }) => ({
  backgroundImage: 'url(/images/abki.png)', // Replace with your hero background image
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: '#fff',
  padding: theme.spacing(12, 0),
  textAlign: 'center',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,

  
}));

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
}));

const ValueCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[6],
  },
}));

const TeamMemberCard = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[6],
  },
}));

function AboutUsPage() {
  // Team Members Data
  const teamMembers = [
    {
      id: 1,
      name: 'Jane Doe',
      position: 'Chief Executive Officer',
      image: '/images/team/jane_doe.jpg', // Ensure this path is correct
      bio: 'Jane is the visionary behind KIRUPAM, with over 15 years of experience in the transportation industry.',
      social: {
        linkedin: 'https://linkedin.com/in/janedoe',
        twitter: 'https://twitter.com/janedoe',
        facebook: 'https://facebook.com/janedoe',
      },
    },
    {
      id: 2,
      name: 'John Smith',
      position: 'Chief Technology Officer',
      image: '/images/team/john_smith.jpg',
      bio: 'John leads our tech team, ensuring our platform remains cutting-edge and user-friendly.',
      social: {
        linkedin: 'https://linkedin.com/in/johnsmith',
        twitter: 'https://twitter.com/johnsmith',
        facebook: 'https://facebook.com/johnsmith',
      },
    },
    // Add more team members as needed
  ];

  // Company Timeline Data with Icons
  const timelineData = [
    {
      year: '2015',
      title: 'Founded KIRUPAM',
      description: 'KIRUPAM was founded with the mission to revolutionize the taxi booking experience.',
      icon: <FaRegBuilding size={50} color="#FFD700" />,
    },
    {
      year: '2016',
      title: 'Launched Mobile App',
      description: 'Our user-friendly mobile app was launched, allowing users to book taxis seamlessly.',
      icon: <FaMobileAlt size={50} color="#FFD700" />,
    },
    {
      year: '2018',
      title: 'Expanded to 5 Cities',
      description: 'KIRUPAM expanded its services to 5 major cities, catering to a larger user base.',
      icon: <FaGlobe size={50} color="#FFD700" />,
    },
    {
      year: '2020',
      title: 'Introduced Premium Services',
      description: 'We introduced premium services including luxury cars and executive rides.',
      icon: <FaCrown size={50} color="#FFD700" />,
    },
    {
      year: '2023',
      title: 'Reached 1 Million Users',
      description: 'Celebrating the milestone of serving over 1 million satisfied users worldwide.',
      icon: <FaUsers size={50} color="#FFD700" />,
    },
    // Add more milestones as needed
  ];

  return (
    <>
      <NavBar />
      {/* Hero Section */}
      <HeroSection
        component={motion.div}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Container>
          <Typography variant="h2" gutterBottom>
            About KIRUPAM
          </Typography>
          <Typography variant="h5">
            Revolutionizing the way you book taxis, with reliability, comfort, and efficiency.
          </Typography>
        </Container>
      </HeroSection>

      <Container>
        {/* Company Overview */}
        <Section
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom>
                Our Story
              </Typography>
              <Typography variant="body1" paragraph>
                Founded in 2015, KIRUPAM has been dedicated to transforming the transportation industry by providing a seamless and user-friendly taxi booking experience. Our mission is to connect people with reliable and comfortable transportation options, ensuring every journey is safe and enjoyable.
              </Typography>
              <Typography variant="body1" paragraph>
                With a team of passionate professionals, we continuously innovate to meet the evolving needs of our customers. From our humble beginnings in a single city, we have grown into a multi-city service provider, making commuting easier for millions.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <img src="/images/abki.png" alt="Our Company" style={{ width: '100%', borderRadius: '8px' }} />
              </Box>
            </Grid>
          </Grid>
        </Section>

        {/* Mission & Vision */}
        <Section
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Mission & Vision
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {/* Mission */}
            <Grid item xs={12} sm={6}>
              <Box sx={{ textAlign: 'center', p: 4, bgcolor: '#F3F4F6', borderRadius: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Our Mission
                </Typography>
                <Typography variant="body1">
                  To provide reliable, efficient, and comfortable transportation solutions that enhance the daily lives of our customers.
                </Typography>
              </Box>
            </Grid>
            {/* Vision */}
            <Grid item xs={12} sm={6}>
              <Box sx={{ textAlign: 'center', p: 4, bgcolor: '#F3F4F6', borderRadius: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Our Vision
                </Typography>
                <Typography variant="body1">
                  To be the leading taxi service provider globally, recognized for our commitment to excellence, innovation, and customer satisfaction.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Section>

        {/* Our Values */}
        <Section
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Our Values
          </Typography>
          <Typography variant="h6" align="center" sx={{ mb: 6 }}>
            Guiding principles that drive our success
          </Typography>
          <Grid container spacing={4}>
            {/* Value 1 */}
            <Grid item xs={12} sm={6} md={4}>
              <ValueCard>
                <FaCheckCircle size={40} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Customer Focus
                </Typography>
                <Typography variant="body2">
                  We prioritize our customers' needs, ensuring their satisfaction with every ride.
                </Typography>
              </ValueCard>
            </Grid>
            {/* Value 2 */}
            <Grid item xs={12} sm={6} md={4}>
              <ValueCard>
                <FaCheckCircle size={40} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Integrity
                </Typography>
                <Typography variant="body2">
                  We conduct our business with honesty and uphold the highest ethical standards.
                </Typography>
              </ValueCard>
            </Grid>
            {/* Value 3 */}
            <Grid item xs={12} sm={6} md={4}>
              <ValueCard>
                <FaCheckCircle size={40} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Innovation
                </Typography>
                <Typography variant="body2">
                  We embrace creativity and continuously seek innovative solutions to enhance our services.
                </Typography>
              </ValueCard>
            </Grid>
            {/* Value 4 */}
            <Grid item xs={12} sm={6} md={4}>
              <ValueCard>
                <FaCheckCircle size={40} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Teamwork
                </Typography>
                <Typography variant="body2">
                  We believe in the power of collaboration and work together to achieve our goals.
                </Typography>
              </ValueCard>
            </Grid>
            {/* Value 5 */}
            <Grid item xs={12} sm={6} md={4}>
              <ValueCard>
                <FaCheckCircle size={40} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Excellence
                </Typography>
                <Typography variant="body2">
                  We strive for excellence in everything we do, ensuring top-notch quality and service.
                </Typography>
              </ValueCard>
            </Grid>
            {/* Value 6 */}
            <Grid item xs={12} sm={6} md={4}>
              <ValueCard>
                <FaCheckCircle size={40} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Sustainability
                </Typography>
                <Typography variant="body2">
                  We are committed to sustainable practices that benefit our community and environment.
                </Typography>
              </ValueCard>
            </Grid>
          </Grid>
        </Section>

     

        {/* Company Timeline Section */}
        <Section
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Our Journey
          </Typography>
          <Typography variant="h6" align="center" sx={{ mb: 6 }}>
            Milestones that shaped KIRUPAM
          </Typography>
          <Timeline position="alternate">
            {timelineData.map((milestone, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent color="text.secondary">
                  {milestone.year}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary">
                    <FaAward />
                  </TimelineDot>
                  {index < timelineData.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Card sx={{ maxWidth: 400, padding: 2 }}>
                    <CardContent sx={{ textAlign: 'center' }}>
                      {/* Display Icon Instead of Image */}
                      <Box sx={{ mb: 2 }}>
                        {milestone.icon}
                      </Box>
                      <Typography variant="h6" component="div">
                        {milestone.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {milestone.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Section>

        {/* Awards & Recognition Section */}
        <Section
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Awards & Recognition
          </Typography>
          <Typography variant="h6" align="center" sx={{ mb: 6 }}>
            Celebrating our achievements and accolades
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {/* Award 1 */}
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                }}
              >
                <FaAward size={50} color="#FFD700" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Best Startup 2020
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Recognized as the most promising startup in the transportation sector.
                </Typography>
              </Box>
            </Grid>

            {/* Award 2 */}
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                }}
              >
                <FaAward size={50} color="#FFD700" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Customer Excellence Award
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Honored for outstanding customer service and satisfaction.
                </Typography>
              </Box>
            </Grid>

            {/* Award 3 */}
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                }}
              >
                <FaAward size={50} color="#FFD700" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Innovation in Tech
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Awarded for integrating cutting-edge technology into our services.
                </Typography>
              </Box>
            </Grid>

            {/* Award 4 */}
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                }}
              >
                <FaAward size={50} color="#FFD700" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Sustainability Leader
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Recognized for our commitment to sustainable and eco-friendly practices.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Section>
      </Container>

      {/* Contact Information Section */}
      <Box
        component={motion.div}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        sx={{
          py: 8,
          bgcolor: '#F3F4F6',
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Get in Touch
        </Typography>
        <Typography variant="h6" sx={{ mb: 6 }}>
          We'd love to hear from you!
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {/* Contact Info */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Address
            </Typography>
            <Typography variant="body2" color="text.secondary">
            193/E Station Road Vavuniya ,<br/> Vavuniya, Sri Lanka
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Email
            </Typography>
            <Typography variant="body2" color="text.secondary">
            kirupamfood@gmail.com
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Phone
            </Typography>
            <Typography variant="body2" color="text.secondary">
            +94 77 771 6888
            </Typography>
          </Grid>
        </Grid>
        {/* Social Media Links */}
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
          <a href="https://linkedin.com/company/kirupam" target="_blank" rel="noopener noreferrer">
            <FaCheckCircle size={30} color="#0A84FF" />
          </a>
          <a href="https://twitter.com/kirupam" target="_blank" rel="noopener noreferrer">
            <FaCheckCircle size={30} color="#0A84FF" />
          </a>
          <a href="https://facebook.com/kirupam" target="_blank" rel="noopener noreferrer">
            <FaCheckCircle size={30} color="#0A84FF" />
          </a>
        </Box>
      </Box>

     
      
      {/* Footer */}
      <Footer />
    </>
  );
}

export default AboutUsPage;
