// src/theme.js
import { createTheme } from '@mui/material/styles';

// Define custom colors
const theme = createTheme({
  palette: {
    primary: {
      main: '#fcd6ff', // Vibrant Coral
    },
    secondary: {
      main: '#6B5B95', // Rich Purple
    },
    success: {
      main: '#88B04B', // Fresh Green
    },
    info: {
      main: '#F7CAC9', // Soft Pink
    },
    warning: {
      main: '#92A8D1', // Light Blue
    },
    error: {
      main: '#955251', // Deep Red
    },
    background: {
      default: '#FFFFFF', // White
      paper: '#F0F4F8', // Light Grey for Paper Components
    },
  },
  typography: {
    h2: {
      fontWeight: 'bold',
      color: '#333333', // Dark Grey for better readability
    },
    h4: {
      fontWeight: 'bold',
      color: '#FF6F61',
    },
    h5: {
      color: '#333333',
    },
    h6: {
      color: '#6B5B95',
    },
    body1: {
      color: '#555555',
    },
    subtitle1: {
      color: '#777777',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Rounded corners for buttons
          textTransform: 'none', // Keep button text casing as is
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12, // Rounded corners for cards
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)', // Soft shadow
        },
      },
    },
  },
});

export default theme;
