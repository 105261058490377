// src/components/NavBar.js

import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';

// Styled Link to remove default styling and add custom styles
const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
}));

function NavBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust breakpoint as needed
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const location = useLocation(); // To determine the active link

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Navigation Links
  const navLinks = [
    { title: 'Home', path: '/' },
    { title: 'Food Order', path: '/food-order' },
    { title: 'Taxi Booking', path: '/taxi-booking' },
    // { title: 'Delivery Service', path: '/delivery-service' }, // Additional link
    { title: 'About Us', path: '/about-us' }, // Additional link
    { title: 'Contact', path: '/contact' }, // Additional link
  ];

  // Drawer Component for Mobile View
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ width: 250 }}
      role="presentation"
    >
      <List>
        {navLinks.map((item) => (
          <ListItem
            button
            key={item.title}
            component={Link}
            to={item.path}
            selected={location.pathname === item.path}
          >
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
      <Divider />
      {/* Optional: Add additional items like social media links or settings */}
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        color="primary"
        elevation={0}
        sx={{
          transition: 'box-shadow 0.3s',
          boxShadow: theme.shadows[1],
        //   bgcolor: '#ffffff', // White background
          color: '#333333', // Dark text for contrast
        }}
      >
        <Toolbar
          sx={{
            px: { xs: 2, sm: 4, md: 10 }, // Responsive horizontal padding
            py: { xs: 1, sm: 2 }, // Responsive vertical padding
          }}
        >
          {/* Logo and Company Name */}
          <StyledLink to="/">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* Logo Image */}
              <Box
                component="img"
                src="/images/logo.png" // Replace with your logo path
                alt="KIRUPAM Logo"
                sx={{
                  height: 60, // Optimized logo height
                  width: 60,  // Optimized logo width
                  mr: 1,     // Margin right for spacing
                }}
              />
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontWeight: 'bold',
                  display: { xs: 'none', sm: 'block' }, // Hide company name on very small screens if desired
                }}
              >
                KIRUPAM (PVT) LTD
              </Typography>
            </Box>
          </StyledLink>

          {/* Spacer to push navigation links to the right */}
          <Box sx={{ flexGrow: 1 }} />

          {/* Desktop Navigation Links */}
          {!isMobile && (
            <Box sx={{ display: 'flex', gap: 3 }}>
              {navLinks.map((item) => (
                <StyledLink key={item.title} to={item.path}>
                  <Button
                    color="inherit"
                    sx={{
                      fontSize: '1rem',
                      fontWeight: location.pathname === item.path ? 'bold' : 'normal',
                      borderBottom:
                        location.pathname === item.path
                          ? `2px solid ${theme.palette.secondary.main}`
                          : '2px solid transparent',
                      transition: 'border-bottom 0.3s',
                      '&:hover': {
                        borderBottom: `2px solid ${theme.palette.secondary.main}`,
                        backgroundColor: 'transparent',
                      },
                      textTransform: 'none', // Keep button text as-is
                    }}
                  >
                    {item.title}
                  </Button>
                </StyledLink>
              ))}
            </Box>
          )}

          {/* Mobile Hamburger Menu */}
          {isMobile && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{
                ml: 2,
                fontSize: '1.5rem', // Make the hamburger icon bigger
              }}
            >
              <MenuIcon sx={{ fontSize: '2rem' }} /> {/* Larger icon size */}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Offset for Fixed AppBar */}
      <Toolbar />

      {/* Drawer for Mobile Navigation */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
}

export default NavBar;
