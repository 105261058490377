// src/components/Footer.js

import React from 'react';
import { Typography, Container, Box, Grid, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from 'react-icons/fa';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'secondary.main',
        color: '#fff',
        py: 6,
        mt: 'auto', // Pushes the footer to the bottom
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* About Us */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              About Us
            </Typography>
            <Typography variant="body2">
              KIRUPAM (PVT) LTD is committed to providing top-notch services in food ordering,
              taxi booking, and delivery services. Our mission is to make your life easier and more
              enjoyable.
            </Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              Quick Links
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <MuiLink component={Link} to="/" color="inherit" underline="hover">
                Home
              </MuiLink>
              <MuiLink component={Link} to="/food-order" color="inherit" underline="hover">
                Food Order
              </MuiLink>
              <MuiLink component={Link} to="/taxi-booking" color="inherit" underline="hover">
                Taxi Booking
              </MuiLink>
              <MuiLink component={Link} to="/delivery-service" color="inherit" underline="hover">
                Delivery Service
              </MuiLink>
              <MuiLink component={Link} to="/about-us" color="inherit" underline="hover">
                About Us
              </MuiLink>
              <MuiLink component={Link} to="/contact" color="inherit" underline="hover">
                Contact
              </MuiLink>
            </Box>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              Contact Us
            </Typography>
            <Typography variant="body2">
              <strong>Address:</strong> 193/E Station Road Vavuniya , Vavuniya, Sri Lanka
            </Typography>
            <Typography variant="body2">
              <strong>Phone:</strong> +94 77 771 6888
            </Typography>
            <Typography variant="body2">
              <strong>Email:</strong> kirupamfood@gmail.com
            </Typography>
          </Grid>

          {/* Follow Us */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              Follow Us
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
              <MuiLink
                href="https://www.facebook.com/kirupamfood"
                target="_blank"
                rel="noopener"
                color="inherit"
                aria-label="Facebook"
              >
                <FaFacebookF size={24} />
              </MuiLink>
              <MuiLink
                href="#"
                target="_blank"
                rel="noopener"
                color="inherit"
                aria-label="Twitter"
              >
                <FaTwitter size={24} />
              </MuiLink>
              <MuiLink
                href="#"
                target="_blank"
                rel="noopener"
                color="inherit"
                aria-label="LinkedIn"
              >
                <FaLinkedinIn size={24} />
              </MuiLink>
              <MuiLink
                href="#"
                target="_blank"
                rel="noopener"
                color="inherit"
                aria-label="Instagram"
              >
                <FaInstagram size={24} />
              </MuiLink>
            </Box>
          </Grid>
        </Grid>

        {/* Divider */}
        <Box sx={{ borderTop: '1px solid rgba(255,255,255,0.2)', mt: 4, pt: 2 }}>
          <Typography variant="body2" align="center">
            © {new Date().getFullYear()} KIRUPAM (PVT) LTD. All rights reserved. || Developed by Lemda (Pvt) Ltd
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
