// src/pages/FoodOrderPage.js

import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Snackbar,
  Alert,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
} from '@mui/material';
import { motion } from 'framer-motion';
import { SiGoogleplay, SiAppstore } from 'react-icons/si';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import { FaCheckCircle } from 'react-icons/fa';
import axios from 'axios';

function FoodOrderPage() {
  // State for form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    interest: '',
    message: '',
  });

  // State for form errors
  const [formErrors, setFormErrors] = useState({});

  // State for submission status
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  // State for stores data
  const [stores, setStores] = useState([]);
  const [loadingStores, setLoadingStores] = useState(true);
  const [storesError, setStoresError] = useState(null);

  // Fetch stores data from API on component mount
  useEffect(() => {
    const fetchStores = async () => {
      try {
        const response = await axios.get('https://kirupam.shop/web/store.php'); // Adjust the API URL if necessary
        setStores(response.data);
        setLoadingStores(false);
      } catch (error) {
        console.error('Error fetching stores:', error);
        setStoresError('Failed to load stores. Please try again later.');
        setLoadingStores(false);
      }
    };

    fetchStores();
  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Remove error message for the field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  // Validate form data
  const validate = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = 'Name is required.';
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required.';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      errors.email = 'Invalid email address.';
    }

    if (!formData.interest) {
      errors.interest = 'Please select an interest.';
    }

    if (!formData.message.trim()) {
      errors.message = 'Message is required.';
    }

    return errors;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = validate();

    if (Object.keys(errors).length !== 0) {
      setFormErrors(errors);
      return;
    }

    // Simulate form submission (e.g., API call)
    // Replace this with actual API integration
    setTimeout(() => {
      // Assuming submission is successful
      setSubmitSuccess(true);
      // Reset form
      setFormData({
        name: '',
        email: '',
        interest: '',
        message: '',
      });
    }, 1000);

    // To simulate an error, uncomment the following lines:
    /*
    setTimeout(() => {
      setSubmitError(true);
    }, 1000);
    */
  };

  // Handle Snackbar close
  const handleCloseSnackbar = () => {
    setSubmitSuccess(false);
    setSubmitError(false);
  };

  return (
    <>
      <NavBar />
      <Container sx={{ mt: 5, mb: 5 }}>
        {/* Hero Section */}
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          sx={{
            bgcolor: '#F3F4F6',
            p: 8,
            borderRadius: 2,
            boxShadow: 3,
            textAlign: 'center',
          }}
        >
          <Typography variant="h3" gutterBottom>
            Welcome to KIRUPAM
          </Typography>
          <Typography variant="h6" sx={{ mt: 2, mb: 4 }}>
            Your all-in-one solution for food ordering and delivery services.
          </Typography>

          {/* Download Buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              flexWrap: 'wrap',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              startIcon={<SiGoogleplay size={24} />}
              href="https://bit.ly/KIRUPAM1" // Replace with actual link
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                minWidth: 150,
                p: 1.5,
                fontSize: '1rem',
                borderRadius: 2,
                boxShadow: 3,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 6,
                },
              }}
            >
              Google Play
            </Button>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<SiAppstore size={24} />}
              href="https://bit.ly/Kirupam" // Replace with actual link
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                minWidth: 150,
                p: 1.5,
                fontSize: '1rem',
                borderRadius: 2,
                boxShadow: 3,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 6,
                },
              }}
            >
              App Store
            </Button>
          </Box>
        </Box>

        {/* App Features Section */}
        <Box
          sx={{
            p: 8,
            mt: 8,
            borderRadius: 2,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            App Features
          </Typography>
          <Typography variant="h6" align="center" sx={{ mt: 2, mb: 6 }}>
            Discover what makes KIRUPAM the best choice for your daily needs.
          </Typography>

          <Grid container spacing={4}>
            {/* Feature 1 */}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 4,
                  textAlign: 'center',
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: 6,
                  },
                }}
              >
                <FastfoodIcon color="secondary" sx={{ fontSize: 50, mb: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Seamless Food Ordering
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Order your favorite meals from top restaurants with just a few taps.
                </Typography>
              </Card>
            </Grid>

            {/* Feature 2 */}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 4,
                  textAlign: 'center',
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: 6,
                  },
                }}
              >
                <DeliveryDiningIcon color="secondary" sx={{ fontSize: 50, mb: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Efficient Delivery Services
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Get your orders delivered to your doorstep swiftly and securely.
                </Typography>
              </Card>
            </Grid>

            {/* Feature 3 */}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 4,
                  textAlign: 'center',
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: 6,
                  },
                }}
              >
                <MobileFriendlyIcon color="secondary" sx={{ fontSize: 50, mb: 2 }} />
                <Typography variant="h6" gutterBottom>
                  User-Friendly Interface
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Navigate through our app with ease and enjoy a seamless experience.
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* How It Works Section */}
        <Box
          sx={{
            bgcolor: '#F3F4F6',
            p: 8,
            mt: 8,
            borderRadius: 2,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            How It Works
          </Typography>
          <Typography variant="h6" align="center" sx={{ mt: 2, mb: 6 }}>
            Simple steps to get started with KIRUPAM.
          </Typography>

          <Grid container spacing={4}>
            {/* Step 1 */}
            <Grid item xs={12} sm={6} md={3}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  cursor: 'pointer',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: 6,
                  },
                }}
              >
                <FaCheckCircle size={40} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Download the App
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Available on Google Play and the App Store. Install the app to begin.
                </Typography>
              </Box>
            </Grid>

            {/* Step 2 */}
            <Grid item xs={12} sm={6} md={3}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  cursor: 'pointer',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: 6,
                  },
                }}
              >
                <FaCheckCircle size={40} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Create an Account
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Sign up with your email or social media accounts to get started.
                </Typography>
              </Box>
            </Grid>

            {/* Step 3 */}
            <Grid item xs={12} sm={6} md={3}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  cursor: 'pointer',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: 6,
                  },
                }}
              >
                <FaCheckCircle size={40} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Explore Features
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Browse through our services and choose what you need.
                </Typography>
              </Box>
            </Grid>

            {/* Step 4 */}
            <Grid item xs={12} sm={6} md={3}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  cursor: 'pointer',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: 6,
                  },
                }}
              >
                <FaCheckCircle size={40} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Enjoy Our Services
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Use the app to order food and enjoy quick deliveries with ease.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Our Partners/Restaurants Section */}
        <Box
          sx={{
            p: 8,
            mt: 8,
            borderRadius: 2,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Our Partners
          </Typography>
          <Typography variant="h6" align="center" sx={{ mt: 2, mb: 6 }}>
            We collaborate with top restaurants to bring you the best dining experience.
          </Typography>

          {/* Handle loading, error, and display stores */}
          {loadingStores ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : storesError ? (
            <Typography variant="body1" color="error" align="center">
              {storesError}
            </Typography>
          ) : (
            <Grid container spacing={4}>
              {stores.map((store) => (
                <Grid item xs={12} sm={6} md={4} key={store.id}>
                  <Card
                    sx={{
                      p: 2,
                      textAlign: 'center',
                      bgcolor: '#fff',
                      borderRadius: 2,
                      boxShadow: 3,
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'translateY(-10px)',
                        boxShadow: 6,
                      },
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {/* Store Logo */}
                    <CardMedia
                      component="img"
                      image={store.logo} // Full URL from backend
                      alt={`${store.name} Logo`}
                      loading="lazy"
                      onError={(e) => { 
                        e.target.onerror = null; 
                        e.target.src = '/images/placeholder.png'; // Ensure this path is correct
                      }}
                      sx={{
                        width: '100%',
                        height: 140,
                        objectFit: 'contain',
                        mb: 2,
                      }}
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" gutterBottom>
                        {store.name}
                      </Typography>
                      {/* <Typography variant="body2" color="text.secondary">
                        {store.address}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        Contact: {store.contact_number}
                      </Typography> */}
                      {/* Optional: Display rating */}
                      {store.rating && (
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          Rating: {store.rating} / 5
                        </Typography>
                      )}
                      {/* Optional: Display reviews */}
                      {store.reviews && (
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          Reviews: {store.reviews}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        {/* FAQ Section (Optional) */}
        <Box
          sx={{
            bgcolor: '#F3F4F6',
            p: 8,
            mt: 8,
            borderRadius: 2,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Frequently Asked Questions
          </Typography>
          <Typography variant="h6" align="center" sx={{ mt: 2, mb: 6 }}>
            Got questions? We have answers.
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            {/* FAQ 1 */}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  How do I place an order?
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Simply browse through the available restaurants, select your favorite dishes, and place your order with a few taps.
                </Typography>
              </Card>
            </Grid>

            {/* FAQ 2 */}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  What payment methods are accepted?
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  We accept various payment methods, including credit/debit cards, digital wallets, and cash on delivery.
                </Typography>
              </Card>
            </Grid>

            {/* FAQ 3 */}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  How can I track my delivery?
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Once your order is placed, you can track the delivery status in real-time through the app.
                </Typography>
              </Card>
            </Grid>

            {/* Add more FAQs as needed */}
          </Grid>
        </Box>

        {/* Interactive Form Section */}
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          sx={{
            bgcolor: '#FFFFFF',
            p: 8,
            mt: 8,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Stay Updated with KIRUPAM
          </Typography>
          <Typography variant="h6" align="center" sx={{ mt: 2, mb: 4 }}>
            Subscribe to our newsletter to receive the latest updates and exclusive offers.
          </Typography>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={8} md={6}>
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      error={!!formErrors.name}
                      helperText={formErrors.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!formErrors.email}
                      helperText={formErrors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth required error={!!formErrors.interest}>
                      <InputLabel id="interest-select-label">I'm Interested In</InputLabel>
                      <Select
                        labelId="interest-select-label"
                        id="interest-select"
                        name="interest"
                        value={formData.interest}
                        label="I'm Interested In"
                        onChange={handleChange}
                      >
                        <MenuItem value="Features">Features</MenuItem>
                        <MenuItem value="Pricing">Pricing</MenuItem>
                        <MenuItem value="Partnership">Partnership</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                      {formErrors.interest && (
                        <Typography variant="caption" color="error">
                          {formErrors.interest}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      multiline
                      minRows={4}
                      label="Message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      error={!!formErrors.message}
                      helperText={formErrors.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      size="large"
                      sx={{
                        p: 1.5,
                        fontSize: '1rem',
                        borderRadius: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Testimonials Section */}
        <Box
          sx={{
            bgcolor: '#F3F4F6',
            p: 8,
            mt: 8,
            borderRadius: 2,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            What Our Users Say
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {/* Testimonial 1 */}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              >
                <Typography variant="body1" gutterBottom>
                  "The KIRUPAM app has revolutionized the way I order food. It's so convenient and reliable!"
                </Typography>
                <Typography variant="subtitle2" color="secondary.main">
                  - Alex Johnson
                </Typography>
              </Card>
            </Grid>

            {/* Testimonial 2 */}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              >
                <Typography variant="body1" gutterBottom>
                  "Efficient delivery services and an intuitive interface make the KIRUPAM app a must-have for daily needs."
                </Typography>
                <Typography variant="subtitle2" color="secondary.main">
                  - Maria Smith
                </Typography>
              </Card>
            </Grid>

            {/* Testimonial 3 */}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                }}
              >
                <Typography variant="body1" gutterBottom>
                  "Joining as a delivery partner through KIRUPAM was the best decision. The app makes managing deliveries effortless."
                </Typography>
                <Typography variant="subtitle2" color="secondary.main">
                  - John Doe
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Success Snackbar */}
      <Snackbar
        open={submitSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
          icon={<CheckCircleIcon fontSize="inherit" />}
        >
          Thank you for your interest! We'll get back to you soon.
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={submitError}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
          icon={<CheckCircleIcon fontSize="inherit" />}
        >
          Oops! Something went wrong. Please try again later.
        </Alert>
      </Snackbar>
      <Footer />
    </>
  );
}

export default FoodOrderPage;
