// src/pages/TaxiBookingPage.js

import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  Button,
  Snackbar,
  Alert,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Rating,
  ButtonGroup,
} from '@mui/material';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { motion } from 'framer-motion';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FaCheckCircle } from 'react-icons/fa';
import { SiGoogleplay, SiAppstore } from 'react-icons/si';
import { styled } from '@mui/system';

function TaxiBookingPage() {
  // State for form data
  const [formData, setFormData] = useState({
    pickupLocation: '',
    dropoffLocation: '',
    bookingDate: null,
    bookingTime: null,
  });

  // State for form errors
  const [errors, setErrors] = useState({});

  // State for submission status
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [bookingError, setBookingError] = useState(false);

  // Available Vehicles Data
  const availableVehicles = [
    {
      id: 1,
      name: 'Three Wheeler',
      type: 'Auto Rickshaw',
      capacity: 3,
      image: '/images/three_wheeler.png',
      rating: 4.5,
      reviews: 120,
    },
    {
      id: 2,
      name: 'Mini Car',
      type: 'Hatchback',
      capacity: 4,
      image: '/images/mini_car.png',
      rating: 4.0,
      reviews: 85,
    },
    {
      id: 3,
      name: 'Flex Car',
      type: 'Sedan',
      capacity: 4,
      image: '/images/flexible_car.png',
      rating: 4.2,
      reviews: 95,
    },
    {
      id: 4,
      name: 'Special Car',
      type: 'Luxury Sedan',
      capacity: 4,
      image: '/images/car.png',
      rating: 4.8,
      reviews: 60,
    },
    {
      id: 5,
      name: 'Bajaj Qute',
      type: 'Executive Car',
      capacity: 4,
      image: '/images/qute1.png',
      rating: 5.0,
      reviews: 40,
    },
    {
      id: 6,
      name: '7 Seater',
      type: 'SUV',
      capacity: 7,
      image: '/images/special_car_fixed.png',
      rating: 4.3,
      reviews: 110,
    },
    {
      id: 7,
      name: 'Mini Van',
      type: 'Van',
      capacity: 6,
      image: '/images/mini_van.png',
      rating: 4.1,
      reviews: 75,
    },
    {
      id: 8,
      name: 'KDH',
      type: 'KDH Car',
      capacity: 4,
      image: '/images/kdh.png',
      rating: 3.9,
      reviews: 50,
    },
    // Add more vehicles as needed
  ];

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Remove error message for the field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  // Handle date change
  const handleDateChange = (newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      bookingDate: newValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      bookingDate: '',
    }));
  };

  // Handle time change
  const handleTimeChange = (newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      bookingTime: newValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      bookingTime: '',
    }));
  };

  // Validate form data
  const validate = () => {
    const tempErrors = {};

    if (!formData.pickupLocation.trim()) {
      tempErrors.pickupLocation = 'Pick-up location is required.';
    }

    if (!formData.dropoffLocation.trim()) {
      tempErrors.dropoffLocation = 'Drop-off location is required.';
    }

    if (!formData.bookingDate) {
      tempErrors.bookingDate = 'Booking date is required.';
    }

    if (!formData.bookingTime) {
      tempErrors.bookingTime = 'Booking time is required.';
    }

    return tempErrors;
  };

  // Handle form submission
  const handleBooking = (e) => {
    e.preventDefault();

    const tempErrors = validate();

    if (Object.keys(tempErrors).length !== 0) {
      setErrors(tempErrors);
      return;
    }

    // Implement booking logic here
    // For example, send booking data to your backend API

    // Since backend booking logic is not implemented, simulate success
    setTimeout(() => {
      setBookingSuccess(true);
      // Reset form
      setFormData({
        pickupLocation: '',
        dropoffLocation: '',
        bookingDate: null,
        bookingTime: null,
      });
    }, 1000);

    // To simulate an error, uncomment the following lines:
    /*
    setTimeout(() => {
      setBookingError(true);
    }, 1000);
    */
  };

  // Handle Snackbar close
  const handleCloseSnackbar = () => {
    setBookingSuccess(false);
    setBookingError(false);
  };

  // Styled Hero Section
  const HeroSection = styled(Box)(({ theme }) => ({
    backgroundColor: 'rgb(226,135,67)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    padding: theme.spacing(8, 0),
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    marginBottom: theme.spacing(8),

    

    // Content should be above the overlay
    '& > *': {
      position: 'relative',
      zIndex: 2,
    },
  }));

  return (
    <>
      <NavBar />
      <Container sx={{ mt: 5, mb: 5 }}>
        {/* Hero Section */}
        <HeroSection
          component={motion.div}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <Typography variant="h2" gutterBottom>
            Experience Seamless Taxi Booking
          </Typography>
          <Typography variant="h5" sx={{ mb: 4 }}>
            Book a ride in just a few taps and enjoy the journey with KIRUPAM.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<SiGoogleplay />}
            href="https://bit.ly/KLIMO-AS" // Replace with your actual Google Play link
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              mr: 2,
              mb: { xs: 2, sm: 0 },
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: 6,
              },
            }}
          >
            Google Play
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<SiAppstore />}
            href="https://bit.ly/KLIMO-AS" // Replace with your actual App Store link
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: 6,
              },
            }}
          >
            App Store
          </Button>
        </HeroSection>

        

        {/* Available Vehicles Section */}
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          sx={{
            p: 8,
            borderRadius: 2,
            boxShadow: 3,
            bgcolor: '#FFFFFF',
            mb: 8,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Available Vehicles
          </Typography>
          <Typography variant="h6" align="center" sx={{ mt: 2, mb: 6 }}>
            Choose from our wide range of vehicles to suit your needs.
          </Typography>

          <Grid container spacing={4}>
            {availableVehicles.map((vehicle) => (
              <Grid item xs={12} sm={6} md={4} key={vehicle.id}>
                <Card
                  sx={{
                    p: 2,
                    textAlign: 'center',
                    bgcolor: '#fff',
                    borderRadius: 2,
                    boxShadow: 3,
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: 6,
                    },
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {/* Vehicle Image */}
                  <CardMedia
                    component="img"
                    image={vehicle.image} // Ensure the image path is correct
                    alt={`${vehicle.name} Image`}
                    loading="lazy" // Improves performance by lazy loading images
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/images/vehicles/placeholder.png'; // Path to your placeholder image
                    }}
                    sx={{
                      width: '100%',
                      height: 140,
                      objectFit: 'contain',
                      mb: 2,
                      borderRadius: 1,
                    }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" gutterBottom>
                      {vehicle.name}
                    </Typography>
                    {/* <Typography variant="body2" color="text.secondary">
                      Type: {vehicle.type}
                    </Typography> */}
                    {/* <Typography variant="body2" color="text.secondary">
                      Capacity: {vehicle.capacity} {vehicle.capacity > 1 ? 'Passengers' : 'Passenger'}
                    </Typography> */}
                    {/* Rating */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                      <Rating value={vehicle.rating} precision={0.1} readOnly />
                      <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                        ({vehicle.reviews})
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* App Features Section */}
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          sx={{
            p: 8,
            borderRadius: 2,
            boxShadow: 3,
            bgcolor: '#F3F4F6',
            mb: 8,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            App Features
          </Typography>
          <Typography variant="h6" align="center" sx={{ mt: 2, mb: 6 }}>
            Discover what makes our app the best choice for your daily transportation needs.
          </Typography>

          <Grid container spacing={4}>
            {/* Feature 1 */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: 6,
                  },
                }}
              >
                <FaCheckCircle size={50} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Real-Time Tracking
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Track your taxi in real-time and know exactly when it will arrive.
                </Typography>
              </Box>
            </Grid>

            {/* Feature 2 */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: 6,
                  },
                }}
              >
                <FaCheckCircle size={50} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Easy Booking
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Book your ride with just a few taps and manage your trips effortlessly.
                </Typography>
              </Box>
            </Grid>

            {/* Feature 3 */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: 6,
                  },
                }}
              >
                <FaCheckCircle size={50} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Multiple Payment Options
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Pay securely using various payment methods, including credit cards and digital wallets.
                </Typography>
              </Box>
            </Grid>

            {/* Feature 4 */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: 6,
                  },
                }}
              >
                <FaCheckCircle size={50} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  24/7 Support
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Get assistance anytime with our dedicated customer support team.
                </Typography>
              </Box>
            </Grid>

            {/* Feature 5 */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: 6,
                  },
                }}
              >
                <FaCheckCircle size={50} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Ride History
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  View and manage your past rides and bookings effortlessly.
                </Typography>
              </Box>
            </Grid>

            {/* Feature 6 */}
            <Grid item xs={12} sm={6} md={4}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                sx={{
                  textAlign: 'center',
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    boxShadow: 6,
                  },
                }}
              >
                <FaCheckCircle size={50} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Safe and Reliable
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Trust our experienced drivers and top-notch safety measures for a secure ride.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Testimonials Section */}
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          sx={{
            p: 8,
            borderRadius: 2,
            boxShadow: 3,
            bgcolor: '#F3F4F6',
            mb: 8,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            What Our Users Say
          </Typography>
          <Typography variant="h6" align="center" sx={{ mt: 2, mb: 6 }}>
            Hear from our satisfied customers.
          </Typography>

          <Grid container spacing={4}>
            {/* Testimonial 1 */}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body1" gutterBottom>
                  "The KIRUPAM app has revolutionized the way I book taxis. It's so convenient and reliable!"
                </Typography>
                <Typography variant="subtitle2" color="secondary.main">
                  - Alex Johnson
                </Typography>
              </Card>
            </Grid>

            {/* Testimonial 2 */}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body1" gutterBottom>
                  "Efficient booking process and amazing customer support. Highly recommend KIRUPAM!"
                </Typography>
                <Typography variant="subtitle2" color="secondary.main">
                  - Maria Smith
                </Typography>
              </Card>
            </Grid>

            {/* Testimonial 3 */}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  p: 3,
                  bgcolor: '#fff',
                  borderRadius: 2,
                  boxShadow: 3,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body1" gutterBottom>
                  "Joining as a driver through KIRUPAM was the best decision. The app makes managing rides effortless."
                </Typography>
                <Typography variant="subtitle2" color="secondary.main">
                  - John Doe
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Download App Section */}
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          sx={{
            p: 8,
            borderRadius: 2,
            boxShadow: 3,
            bgcolor: '#FFFFFF',
            textAlign: 'center',
            mb: 8,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Download the K LIMO Driver App
          </Typography>
          <Typography variant="h6" sx={{ mt: 2, mb: 6 }}>
            Get the best taxi Driving experience right at your fingertips.
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<SiGoogleplay size={24} />}
              href="https://bit.ly/KLIMODRIVERGP" // Replace with actual link
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                minWidth: 200,
                p: 1.5,
                fontSize: '1rem',
                borderRadius: 2,
                boxShadow: 3,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 6,
                },
              }}
            >
              Google Play
            </Button>

            <Button
              variant="contained"
              color="secondary"
              startIcon={<SiAppstore size={24} />}
              href="https://bit.ly/KLIMODRIVERAS" // Replace with actual link
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                minWidth: 200,
                p: 1.5,
                fontSize: '1rem',
                borderRadius: 2,
                boxShadow: 3,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 6,
                },
              }}
            >
              App Store
            </Button>
          </Box>
        </Box>
      </Container>

      {/* Booking Success Snackbar */}
      <Snackbar
        open={bookingSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
          icon={<CheckCircleIcon fontSize="inherit" />}
        >
          Your taxi has been booked successfully!
        </Alert>
      </Snackbar>

      {/* Booking Error Snackbar */}
      <Snackbar
        open={bookingError}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
          icon={<CheckCircleIcon fontSize="inherit" />}
        >
          Oops! Something went wrong. Please try again later.
        </Alert>
      </Snackbar>

      <Footer />
    </>
  );
}

export default TaxiBookingPage;
