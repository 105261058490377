// src/pages/ContactUsPage.js

import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  Button,
  Paper,
  Avatar,
  Link,
} from '@mui/material';
import { styled } from '@mui/system';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaLinkedin, FaTwitter, FaFacebook } from 'react-icons/fa';
import { motion } from 'framer-motion';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const HeroSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#eab676', // Replace with your hero background image
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: '#fff',
  padding: theme.spacing(12, 0),
  textAlign: 'center',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,

  // Overlay to darken the background image for better text visibility
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: theme.shape.borderRadius,
    zIndex: 1,
  },

  // Content should be above the overlay
  '& > *': {
    position: 'relative',
    zIndex: 2,
  },
}));

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
}));

const ContactInfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[6],
  },
}));

const ContactUsPage = () => {
  // Form state
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  // Form errors
  const [errors, setErrors] = useState({});

  // Submission status
  const [submitted, setSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear errors as user types
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  // Validate form
  const validate = () => {
    const tempErrors = {};

    if (!formData.name.trim()) tempErrors.name = 'Name is required.';
    if (!formData.email) {
      tempErrors.email = 'Email is required.';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        tempErrors.email = 'Email is not valid.';
      }
    }
    if (!formData.subject.trim()) tempErrors.subject = 'Subject is required.';
    if (!formData.message.trim()) tempErrors.message = 'Message is required.';

    return tempErrors;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const tempErrors = validate();

    if (Object.keys(tempErrors).length !== 0) {
      setErrors(tempErrors);
      return;
    }

    // Simulate form submission (replace with actual API call)
    // For demonstration, we'll just reset the form and show a success message
    setTimeout(() => {
      setSubmitted(true);
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    }, 1000);

    // To simulate an error, uncomment the following lines:
    /*
    setTimeout(() => {
      setSubmissionError(true);
    }, 1000);
    */
  };

  return (
    <>
      <NavBar />

      {/* Hero Section */}
      <HeroSection
        component={motion.div}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Container>
          <Typography variant="h2" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="h5">
            We'd love to hear from you! Get in touch with us for any queries or support.
          </Typography>
        </Container>
      </HeroSection>

      <Container>
        {/* Contact Information */}
        <Section
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <Grid container spacing={4} justifyContent="center">
            {/* Address */}
            <Grid item xs={12} sm={6} md={4}>
              <ContactInfoCard>
                <FaMapMarkerAlt size={40} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Our Address
                </Typography>
                <Typography variant="body2" color="text.secondary">
                193/E Station Road Vavuniya ,<br/>
                Vavuniya, Sri Lanka
                </Typography>
              </ContactInfoCard>
            </Grid>

            {/* Phone */}
            <Grid item xs={12} sm={6} md={4}>
              <ContactInfoCard>
                <FaPhoneAlt size={40} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Call Us
                </Typography>
                <Typography variant="body2" color="text.secondary">
                +94 77 771 6888
                </Typography>
              </ContactInfoCard>
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={6} md={4}>
              <ContactInfoCard>
                <FaEnvelope size={40} color="#0A84FF" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                  Email Us
                </Typography>
                <Typography variant="body2" color="text.secondary">
                kirupamfood@gmail.com
                </Typography>
              </ContactInfoCard>
            </Grid>
          </Grid>
        </Section>

        {/* Contact Form */}
        <Section
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={8}>
              <Paper elevation={3} sx={{ p: 6, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom>
                  Send Us a Message
                </Typography>

                {submitted && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, color: 'success.main' }}>
                    <CheckCircleIcon sx={{ mr: 1 }} />
                    <Typography variant="body1">Your message has been sent successfully!</Typography>
                  </Box>
                )}

                {submissionError && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, color: 'error.main' }}>
                    <CheckCircleIcon sx={{ mr: 1 }} />
                    <Typography variant="body1">There was an error sending your message. Please try again.</Typography>
                  </Box>
                )}

                <form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    {/* Name */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Name"
                        name="name"
                        variant="outlined"
                        fullWidth
                        required
                        value={formData.name}
                        onChange={handleChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                        aria-required="true"
                      />
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Email"
                        name="email"
                        variant="outlined"
                        fullWidth
                        required
                        value={formData.email}
                        onChange={handleChange}
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                        aria-required="true"
                      />
                    </Grid>

                    {/* Subject */}
                    <Grid item xs={12}>
                      <TextField
                        label="Subject"
                        name="subject"
                        variant="outlined"
                        fullWidth
                        required
                        value={formData.subject}
                        onChange={handleChange}
                        error={Boolean(errors.subject)}
                        helperText={errors.subject}
                        aria-required="true"
                      />
                    </Grid>

                    {/* Message */}
                    <Grid item xs={12}>
                      <TextField
                        label="Message"
                        name="message"
                        variant="outlined"
                        fullWidth
                        required
                        multiline
                        rows={6}
                        value={formData.message}
                        onChange={handleChange}
                        error={Boolean(errors.message)}
                        helperText={errors.message}
                        aria-required="true"
                      />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        sx={{ px: 6, py: 1.5 }}
                      >
                        Send Message
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Section>



        {/* Social Media Links */}
        <Section
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Follow Us
          </Typography>
          <Typography variant="h6" align="center" sx={{ mb: 6 }}>
            Connect with us on social media
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
            <Link href="#" target="_blank" rel="noopener noreferrer">
              <FaLinkedin size={40} color="#0A84FF" />
            </Link>
            <Link href="#" target="_blank" rel="noopener noreferrer">
              <FaTwitter size={40} color="#0A84FF" />
            </Link>
            <Link href="https://www.facebook.com/kirupamfood" target="_blank" rel="noopener noreferrer">
              <FaFacebook size={40} color="#0A84FF" />
            </Link>
          </Box>
        </Section>
      </Container>

      <Footer />
    </>
  );
};

export default ContactUsPage;
